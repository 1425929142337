import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import type { AuthProviderEnforcement } from '@neuralegion/api';

@Injectable()
export class OrganizationsAuthProvidersService {
  constructor(private readonly http: HttpClient) {}
  public loadEnforcement(orgId: string): Observable<AuthProviderEnforcement> {
    return this.http.get<AuthProviderEnforcement>(`/api/v1/orgs/${orgId}/auth-provider`);
  }

  public disableForceSso(orgId: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/orgs/${orgId}/auth-provider/enforcement`);
  }
}
