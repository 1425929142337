<sat-popover xAlign="start" yAlign="below" (closed)="onPopoverClose()" (opened)="onPopoverOpen()">
  <form class="popover-container" [attr.data-id]="'overlay_table_filter'" [formGroup]="form">
    <div
      *ngIf="{
        availableFilters: availableFilters$ | async,
        formMode: filterFormModeSubject | async
      } as sync"
      class="filters">
      <h2 class="label">Filter by</h2>
      <div class="filter-section" fxLayout="column wrap" fxLayoutAlign="start start">
        <ng-container *ngIf="filters.value.length" [ngTemplateOutlet]="tmplActiveFilters"></ng-container>
        <button
          *ngIf="sync.formMode === FiltersSettingsMode.VIEW"
          class="text-button"
          mat-button
          [disabled]="!sync.availableFilters?.length"
          (click)="updateFilterFormMode(FiltersSettingsMode.EDIT)">
          <mat-icon>add</mat-icon>
          Add filter
        </button>
        <div
          *ngIf="sync.formMode === FiltersSettingsMode.EDIT"
          class="filter-line"
          fxLayout="row wrap"
          fxLayoutAlign="start start"
          fxLayoutGap="16px">
          <mat-form-field class="compact-field filter-field">
            <mat-select #filtersSelect [formControlName]="'filter'" [placeholder]="'Choose filter'">
              <mat-option
                *ngFor="let availableFilter of sync.availableFilters; trackBy: trackByIdentity"
                [disabled]="availableFilter.status !== FilterStatus.AVAILABLE"
                [value]="availableFilter.config">
                <div fxLayout="row nowrap" fxLayoutAlign="space-between start">
                  {{ availableFilter.config.name | filterLabel: filterLabelsMap }}
                  <div *ngIf="availableFilter.status !== FilterStatus.AVAILABLE">
                    <span class="status" ngClass="status-{{ availableFilter.status }}">
                      {{
                        availableFilter.status
                          | shareFilterStatusLabel: (availableFilter.config.name | filterLabel: filterLabelsMap)
                      }}
                    </span>
                  </div>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container
            *ngIf="form.value.filter?.type"
            [dynamicControl]="{
              controlComponent: form.value.filter.type | filterControl,
              inputs: {
                filterConfig: form.value.filter,
                filters: filters.value,
                filterLabelsMap: filterLabelsMap
              }
            }"
            [formControlName]="'value'">
          </ng-container>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="actions" fxLayout="row" fxLayoutAlign="space-between">
      <button mat-stroked-button (click)="closePopover()">Cancel</button>
      <div fxLayout="row" fxLayoutGap="10px">
        <button class="text-button" mat-button (click)="clearAll()">Clear all</button>
        <button color="primary" mat-raised-button (click)="apply()">Apply</button>
      </div>
    </div>
  </form>
</sat-popover>

<ng-container
  *ngIf="{
    appliedFilters: (appliedFiltersSubject | async).value | unique: 'name',
    pending: pending$ | async
  } as sync">
  <button
    class="text-button"
    attr.aria-label="{{ 'Filter ' + options.tableName }}"
    color="primary"
    mat-button
    [class.filters-set]="sync.appliedFilters.length"
    [matTooltip]="'Filter ' + options.tableName"
    [satPopoverAnchor]="popover"
    [sharePopoverTrigger]="popover">
    <mat-icon>filter_list</mat-icon>
    <span>
      <ng-container *ngIf="sync.appliedFilters.length; else tmplFiltersEmpty">
        <b>
          <ng-container *ngIf="!sync.pending">({{ length }})</ng-container>
          Filtered by
        </b>
        <ng-container *ngFor="let filter of sync.appliedFilters; trackBy: trackByIdentity; last as last">
          {{ filter.name | filterLabel: filterLabelsMap | lowercase }}{{ last ? '' : ', ' }}
        </ng-container>
      </ng-container>
      <ng-template #tmplFiltersEmpty> Filter </ng-template>
    </span>
  </button>
</ng-container>

<ng-template #tmplActiveFilters>
  <mat-chip-listbox class="filter-list" aria-label="Filters" [tabIndex]="-1" [selectable]="false">
    <ng-container *ngFor="let filter of filters.value; let last = last; trackBy: trackByIdx">
      <ng-container
        *ngTemplateOutlet="
          [FilterType.SELECT, FilterType.ASYNC_SELECT, FilterType.EXTERNAL_FILTRATION_SELECT].includes(filter.type)
            ? tmplSelectFilterValue
            : tmplFilterValue;
          context: { filter: filter, filterRef: filter, filterList: filters, last: last }
        ">
      </ng-container>
      <ng-template
        #tmplFilterValue
        let-filter="filter"
        let-filterList="filterList"
        let-filterRef="filterRef"
        let-last="last">
        <mat-chip-option
          [attr.aria-label]="(filter.name | filterLabel: filterLabelsMap) + ': ' + (filter | filterValue)"
          [selectable]="false"
          [shareAutofocus]="last"
          (removed)="removeFilter(filterRef, filterList)">
          {{ filter.name | filterLabel: filterLabelsMap }}:
          {{ filter | filterValue }}
          <button
            [tabIndex]="0"
            [attr.aria-label]="'Remove ' + (filter.name | filterLabel: filterLabelsMap) + ': ' + (filter | filterValue)"
            element-data-id="btn_remove"
            matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-option>
      </ng-template>
      <ng-template #tmplSelectFilterValue let-filter="filter" let-last="last">
        <ng-container *ngFor="let filterItem of filter.value; trackBy: trackByIdx">
          <ng-container
            *ngIf="{
              selectFilterItems: filter.settings.items,
              asyncSelectFilterItems: (filter.settings.items$ | async),
              customSelectFilterItems: filter.settings.searchedItems$ ? (filter.settings.searchedItems$ | async) : []
            } as items">
            <ng-container
              *ngTemplateOutlet="
                tmplFilterValue;
                context: {
                  filterRef: filterItem,
                  filter: {
                    name: filter.name,
                    type: filter.type,
                    value:
                      filter.type === FilterType.EXTERNAL_FILTRATION_SELECT
                        ? (filterItem.value | find: items.customSelectFilterItems : 'value') || filterItem.value
                        : (filterItem.value | find: items.selectFilterItems || items.asyncSelectFilterItems : 'value')
                  },
                  filterList: filter,
                  last: last
                }
              ">
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </ng-container>
  </mat-chip-listbox>
</ng-template>
