import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'share-enabled-status-chip',
  templateUrl: './enabled-status-chip.component.html',
  styleUrls: ['./enabled-status-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnabledStatusChipComponent {
  @Input()
  public enabled: boolean = false;

  @Input()
  public defined: boolean = true;
}
