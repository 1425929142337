export enum FeedActivityObjectType {
  BOARD = 'board',
  COMMENT = 'comment',
  FILE = 'file',
  INTEGRATION = 'integration',
  ISSUE = 'issue',
  ISSUE_SOURCE = 'issue_source',
  IP_RESTRICTION = 'ip_restriction',
  ORGANIZATION = 'organization',
  PAYMENT_METHOD = 'payment_method',
  SCAN = 'scan',
  SUBSCRIPTION = 'subscription',
  REPEATER = 'repeater',
  USER = 'user',
  PROJECT = 'project'
}

export const sortedFeedActivityObjectTypes = Object.values(FeedActivityObjectType).sort(
  (item1: FeedActivityObjectType, item2: FeedActivityObjectType): number =>
    item1.localeCompare(item2)
);
