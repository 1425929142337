import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import {
  disableForceSso,
  disableForceSsoFail,
  disableForceSsoSuccess,
  loadOrgAuthProvider,
  loadOrgAuthProviderFail,
  loadOrgAuthProviderSuccess
} from './org-auth-provider.actions';
import { OrgAuthProviderState, orgAuthProviderInitialState } from './org-auth-provider.state';

export const orgAuthProviderReducer = createReducer<OrgAuthProviderState, Action>(
  orgAuthProviderInitialState,
  on(
    loadOrgAuthProvider,
    disableForceSso,
    (state: OrgAuthProviderState, action): OrgAuthProviderState => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    loadOrgAuthProviderFail,
    disableForceSsoFail,
    (state: OrgAuthProviderState, action): OrgAuthProviderState =>
      cleanupPendingState(state, action, action.payload)
  ),
  on(
    disableForceSsoSuccess,
    (state: OrgAuthProviderState, action): OrgAuthProviderState =>
      cleanupPendingState(
        { ...state, authProvider: { ...state.authProvider, forceSso: false } },
        action
      )
  ),
  on(
    loadOrgAuthProviderSuccess,
    (state: OrgAuthProviderState, action): OrgAuthProviderState =>
      cleanupPendingState(
        {
          ...state,
          authProvider: action.payload.authProvider
        },
        action
      )
  )
);
