export enum UserConsent {
  OUT_OF_SCOPE_TESTS = 'outOfScopeTests',
  BUSINESS_LOGIC_TESTS = 'businessLogicTests',
  RATE_LIMIT_TEST = 'rateLimitTest',
  SMART_SCAN_OFF = 'smartScanOff',
  EXTRA_TARGET_PARAM_LOCATIONS = 'extraTargetParamLocations',
  ADVANCED_TEST_BUCKET = 'advancedTestBucket',
  MULTIPLE_AUTH_ATTACK_TESTS_AND_BUCKET = 'multipleAuthAttack'
}

export type UserConsents = { [key in UserConsent]: boolean };
