import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { SnackbarService } from '@neuralegion/core';
import { ExternalErrorReporterService } from '../services';
import { addHttpError } from './error-handler.actions';

@Injectable()
export class ErrorHandlerEffects {
  public readonly showNotification$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addHttpError),
        tap((action: ReturnType<typeof addHttpError>) => {
          const { error, status, message } = action.payload.error;
          const errorMessage =
            typeof error === 'string' ? error : error?.message ?? error?.title ?? message;

          if (action.payload.options?.skipNotificationPredicate?.(errorMessage)) {
            return;
          }

          switch (status) {
            case 0:
            case 500:
            case 501:
              this.snackbarService.openWithReloadAction(`Error: ${errorMessage}`);
              break;

            case 401:
              this.snackbarService.open(errorMessage, true);
              break;

            default:
              this.snackbarService.open(`Error: ${errorMessage}`, true);
          }
        })
      ),
    { dispatch: false }
  );

  public readonly reportError$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addHttpError),
        tap((action: ReturnType<typeof addHttpError>) => {
          if (action.payload.options?.skipReport) {
            return;
          }

          const error = action.payload.error;
          if (error.status >= 500 || [405, 406, 415].includes(error.status)) {
            this.externalErrorReporter.report(action.payload.error);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly externalErrorReporter: ExternalErrorReporterService,
    private readonly snackbarService: SnackbarService
  ) {}
}
