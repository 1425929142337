import { Action } from '@ngrx/store';
import { type AuthProviderEnforcement } from '@neuralegion/api';

export interface OrgAuthProviderState {
  error: string;
  authProvider?: AuthProviderEnforcement;
  pending: Action[];
}

export const orgAuthProviderInitialState: OrgAuthProviderState = {
  error: null,
  authProvider: null,
  pending: []
};
