import { createAction } from '@ngrx/store';
import { AvailableExternalScanConfigSource, Board, ServiceName } from '@neuralegion/api';
import { AzureOrg, GitlabInstallMetadata, Integration, SnykOrg } from '../models';

export const loadIntegrations = createAction('[Integrations] LOAD_INTEGRATIONS');

export const loadIntegrationsSuccess = createAction(
  '[Integrations] LOAD_INTEGRATIONS_SUCCESS',
  (payload: Integration[]) => ({ payload, parentType: loadIntegrations.type })
);

export const loadIntegrationsFail = createAction(
  '[Integrations] LOAD_INTEGRATIONS_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadIntegrations.type
  })
);

export const addIntegration = createAction(
  '[Integrations] ADD_INTEGRATION',
  (payload: { integration: Omit<Integration, 'id'> }) => ({ payload })
);

export const addGitlabIntegration = createAction(
  '[Integrations] ADD_GITLAB_INTEGRATION',
  (payload: { integration: Omit<Integration, 'id'> }) => ({ payload })
);

export const addGitlabIntegrationSuccess = createAction(
  '[Integrations] ADD_GITLAB_INTEGRATION_SUCCESS',
  (payload: { integration: Omit<Integration, 'id'>; installMetadata: GitlabInstallMetadata }) => ({
    payload,
    parentType: addGitlabIntegration.type
  })
);

export const addGitlabIntegrationFail = createAction(
  '[Integrations] ADD_GITLAB_INTEGRATION_FAIL',
  (payload: string) => ({ payload, parentType: addGitlabIntegration.type })
);

export const addIntegrationSuccess = createAction(
  '[Integrations] ADD_INTEGRATION_SUCCESS',
  (payload: { backToUrl?: string }) => ({ payload, parentType: addIntegration.type })
);

export const addIntegrationFail = createAction(
  '[Integrations] ADD_INTEGRATION_FAIL',
  (payload: string) => ({
    payload,
    parentType: addIntegration.type
  })
);

export const updateIntegration = createAction(
  '[Integrations] UPDATE_INTEGRATION',
  (payload: { integration: Integration }) => ({ payload })
);

export const updateIntegrationSuccess = createAction(
  '[Integrations] UPDATE_INTEGRATION_SUCCESS',
  () => ({
    parentType: updateIntegration.type
  })
);

export const updateIntegrationFail = createAction(
  '[Integrations] UPDATE_INTEGRATION_FAIL',
  (payload: string) => ({
    payload,
    parentType: updateIntegration.type
  })
);

export const removeIntegration = createAction(
  '[Integrations] REMOVE_INTEGRATION',
  (payload: { service: ServiceName; integrationId: string }) => ({ payload })
);

export const removeIntegrationSuccess = createAction(
  '[Integrations] REMOVE_INTEGRATION_SUCCESS',
  (payload: { integrationId: string }) => ({
    payload,
    parentType: removeIntegration.type
  })
);

export const removeIntegrationFail = createAction(
  '[Integrations] REMOVE_INTEGRATION_FAIL',
  (payload: string) => ({
    payload,
    parentType: removeIntegration.type
  })
);

export const loadAzureOrgs = createAction('[Integrations] LOAD_AZURE_ORGS');

export const loadAzureOrgsSuccess = createAction(
  '[Integrations] LOAD_AZURE_ORGS_SUCCESS',
  (payload: AzureOrg[]) => ({
    payload,
    parentType: loadAzureOrgs.type
  })
);

export const loadAzureOrgsFail = createAction(
  '[Integrations] LOAD_AZURE_ORGS_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadAzureOrgs.type
  })
);

export const loadRepositories = createAction(
  '[Integrations] LOAD_REPOSITORIES',
  (payload: { serviceName: ServiceName; integrationId: string }) => ({ payload })
);

export const loadRepositoriesSuccess = createAction(
  '[Integrations] LOAD_REPOSITORIES_SUCCESS',
  (payload: { serviceName: ServiceName; repositories: Board[]; integrationId: string }) => ({
    payload,
    parentType: loadRepositories.type
  })
);

export const loadRepositoriesFail = createAction(
  '[Integrations] LOAD_REPOSITORIES_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadRepositories.type
  })
);

export const loadAvailableExternalScanConfigSources = createAction(
  '[Integrations] LOAD_AVAILABLE_EXTERNAL_SCAN_CONFIG_SOURCES',
  (payload: { serviceName: ServiceName; integrationId: string }) => ({ payload })
);

export const loadAvailableExternalScanConfigSourcesSuccess = createAction(
  '[Integrations] LOAD_AVAILABLE_EXTERNAL_SCAN_CONFIG_SOURCES_SUCCESS',
  (payload: {
    serviceName: ServiceName;
    configSources: AvailableExternalScanConfigSource[];
    integrationId: string;
  }) => ({
    payload,
    parentType: loadAvailableExternalScanConfigSources.type
  })
);

export const loadAvailableExternalScanConfigSourcesFail = createAction(
  '[Integrations] LOAD_AVAILABLE_EXTERNAL_SCAN_CONFIG_SOURCES_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadAvailableExternalScanConfigSources.type
  })
);

export const loadSnykOrgs = createAction(
  '[Integrations] LOAD_SNYK_ORGS',
  (integrationId?: string) => ({
    integrationId
  })
);

export const loadSnykOrgsSuccess = createAction(
  '[Integrations] LOAD_SNYK_ORGS_SUCCESS',
  (payload: SnykOrg[]) => ({
    payload,
    parentType: loadSnykOrgs.type
  })
);

export const loadSnykOrgsFail = createAction(
  '[Integrations] LOAD_SNYK_ORGS_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadSnykOrgs.type
  })
);
