import { createFeatureSelector, createSelector } from '@ngrx/store';
import type { AuthProviderEnforcement } from '@neuralegion/api';
import { OrgAuthProviderState } from './org-auth-provider.state';
import { State } from './state';

export const selectOrgAuthProviderState =
  createFeatureSelector<OrgAuthProviderState>('orgAuthProvider');

export const selectOrgAuthProvider = createSelector<
  State,
  [OrgAuthProviderState],
  AuthProviderEnforcement
>(selectOrgAuthProviderState, (state: OrgAuthProviderState) => state.authProvider);

export const selectOrgAuthProviderPendingStatus = createSelector<
  State,
  [OrgAuthProviderState],
  boolean
>(selectOrgAuthProviderState, (state: OrgAuthProviderState) => state.pending?.length > 0);
