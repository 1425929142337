<mat-form-field
  [ngClass]="{
    'compact-field': options.compact,
    'custom-hint': options.hintPosition === 'above'
  }">
  <mat-label *ngIf="options.tmplLabel">
    <ng-container *ngTemplateOutlet="options.tmplLabel"></ng-container>
  </mat-label>

  <mat-error [matErrorOptions]="{ fieldName: options.itemName | titlecase }">
    <ng-container *ngTemplateOutlet="options.matErrorTmpl || null"></ng-container>
  </mat-error>

  <mat-hint><ng-content select="[field-hint]"></ng-content></mat-hint>

  <mat-select
    *ngIf="{ itemsList: itemsList$ | async } as sync"
    panelClass="panel-server-filterable-select {{ options.multiple ? 'multiple' : '' }} {{
      options.compact ? 'compact' : ''
    }}"
    shareFilterableSelectRoleDialog
    [aria-label]="ariaLabel || (!options.tmplLabel ? options.placeholder : null)"
    [formControl]="selectControl"
    [maxlength]="options.maxLength"
    [minlength]="options.minLength"
    [multiple]="options.multiple"
    [ngClass]="{ 'has-empty-option': showEmptyOption }"
    [placeholder]="options.placeholder"
    [required]="options.required"
    (openedChange)="onOpenedChange($event)">
    <mat-select-trigger fxLayout="row" fxLayoutAlign="space-between center">
      {{ selectControl.value | selectTriggerLabel: sync.itemsList : options.itemName }}
    </mat-select-trigger>

    <div cdkTrapFocus shareFilterableSelectPanelFocus>
      <div class="controls-panel" shareFilterableSelectControlsKeydown>
        <share-text-filter-control
          [formControl]="textFilter"
          [options]="{
            pending,
            placeholder: 'Search…',
            validators: options.filterFieldValidators
          }"></share-text-filter-control>

        <div *ngIf="options.tmplOptionsHint" class="options-hint" [element-data-id]="'hint'">
          <ng-container *ngTemplateOutlet="options.tmplOptionsHint"></ng-container>
        </div>
      </div>

      <div
        *ngIf="sync.itemsList.length > 0; else tmplEmpty"
        class="focus-ring"
        role="listbox"
        tabindex="0"
        [attr.aria-multiselectable]="options.multiple"
        #optionsPanel
        attr.aria-label="{{ options.itemName }} list"
        shareFilterableSelectOptionsFocus
        [class.pending]="pending">
        <mat-option
          *ngFor="let item of sync.itemsList; trackBy: trackByIdentity"
          class="ghost-blur"
          [disabled]="item.disabled || pending"
          [value]="item.id">
          <ng-container *ngTemplateOutlet="options.tmplItem; context: { $implicit: item }"></ng-container>
          <span *ngIf="!options.tmplItem">{{ item.label }}</span>
        </mat-option>

        <div *ngIf="options.tmplItemsFooter" class="items-footer" [element-data-id]="'items_footer'">
          <ng-container *ngTemplateOutlet="options.tmplItemsFooter"></ng-container>
        </div>
      </div>

      <div *ngIf="options.tmplFooter" class="sticky-footer" [element-data-id]="'footer'">
        <ng-container *ngTemplateOutlet="options.tmplFooter"></ng-container>
      </div>

      <ng-template #tmplEmpty>
        <div *ngIf="!pending" class="empty" [element-data-id]="'dataset_empty'">
          <ng-container *ngIf="textFilter?.value; else tmplNoData">No results</ng-container>
          <ng-template #tmplNoData> No {{ '' | simplePlural: options.itemName }} </ng-template>
        </div>
        <mat-option class="no-data-option" disabled></mat-option>
      </ng-template>
    </div>
  </mat-select>
</mat-form-field>
