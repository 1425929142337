<ng-container [ngSwitch]="item.object?.type">
  <ng-container *ngSwitchCase="FeedActivityObjectType.SCAN">
    <ng-container *ngTemplateOutlet="tmplScan; context: item"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="FeedActivityObjectType.ISSUE">
    <ng-container *ngTemplateOutlet="tmplIssue; context: item"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="FeedActivityObjectType.USER">
    <ng-container *ngTemplateOutlet="tmplUser; context: item"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="FeedActivityObjectType.FILE">
    <ng-container *ngTemplateOutlet="tmplFile; context: item"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="FeedActivityObjectType.SUBSCRIPTION">
    <ng-container *ngTemplateOutlet="tmplSubscription; context: item"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="FeedActivityObjectType.COMMENT">
    <ng-container *ngTemplateOutlet="tmplComment; context: item"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="FeedActivityObjectType.REPEATER">
    <ng-container *ngTemplateOutlet="tmplRepeater; context: item"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="FeedActivityObjectType.BOARD">
    <ng-container *ngTemplateOutlet="tmplBoardOrIssueSource; context: item"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="FeedActivityObjectType.ISSUE_SOURCE">
    <ng-container *ngTemplateOutlet="tmplBoardOrIssueSource; context: item"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="FeedActivityObjectType.IP_RESTRICTION">
    <ng-container *ngTemplateOutlet="tmplIPRestriction; context: item"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="FeedActivityObjectType.PROJECT">
    <ng-container *ngTemplateOutlet="tmplProject; context: item"></ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="tmplUnknown; context: item"></ng-container>
  </ng-container>
</ng-container>
<span *ngIf="extra && (item.object.extra || item.target.extra)" class="extra">
  {{ item | feedExtraWording | quotes: '(' : ')' }}
</span>

<ng-template #tmplScan let-actor="actor" let-scan="object" let-target="target" let-verb="verb">
  <ng-container [ngSwitch]="verb === FeedVerb.DISRUPT || verb === FeedVerb.FINISH">
    <ng-container *ngSwitchCase="true">
      <span>Scan</span>
      <a
        class="link link-internal"
        attr.aria-label="Navigate to scan {{ scan.name }}"
        [routerLink]="['/scans', scan.id]"
        >{{ scan.name }}</a
      >
      <span>initiated by</span>
      <ng-container *ngTemplateOutlet="tmplActor; context: { $implicit: actor }"></ng-container>
      <span>has been</span>
      <span class="verb">{{ verb | feedVerbPastTense: scan.type }}</span>
      <span *ngIf="verb === FeedVerb.FINISH">successfully</span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="tmplScanActiveActor; context: { verb, actor, scan, target }"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #tmplScanActiveActor let-actor="actor" let-scan="scan" let-target="target" let-verb="verb">
  <ng-container *ngTemplateOutlet="tmplActor; context: { $implicit: actor }"></ng-container>
  <span class="verb">{{ verb | feedVerbPastTense: scan.type }}</span>
  <span class="type">{{ scan.type | feedObjectType: verb }}</span>
  <ng-container [ngSwitch]="verb">
    <ng-container *ngSwitchCase="FeedVerb.REMOVE">
      <span class="name-removed">{{ scan.name }}</span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <a
        class="link link-internal"
        attr.aria-label="Navigate to scan {{ scan.name }}"
        [routerLink]="['/scans', scan.id]"
        >{{ scan.name }}</a
      >
    </ng-container>
  </ng-container>
  <ng-container [ngSwitch]="verb">
    <ng-container *ngSwitchCase="FeedVerb.SCHEDULE">
      <ng-container *ngIf="scan.extra?.schedule.nextRunAt">
        <span>to run on</span>
        <span class="schedule">{{ scan.extra.schedule.nextRunAt | customDate: DateFormatAlias.DATE_ONLY }}</span>
        <span>at</span>
        <span class="schedule">{{ scan.extra.schedule.nextRunAt | customDate: DateFormatAlias.TIME_ONLY }}</span
        >,
      </ng-container>
      <ng-container [ngSwitch]="scan.extra?.schedule.type">
        <ng-container *ngSwitchCase="'recurrent'">
          <span *ngIf="scan.extra.schedule.nextRunAt">recurring</span>
          <span *ngIf="!scan.extra.schedule.nextRunAt">to recur</span>
          <span class="schedule">{{
            scan.extra.schedule.repeatInterval | feedScheduleIntervalPipe: scan.extra.schedule.repeatIntervalUnit
          }}</span>
          <ng-container *ngIf="scan.extra.schedule.endAt">
            <span>until</span>
            <span class="schedule">{{ scan.extra.schedule.endAt | customDate: DateFormatAlias.DATE_ONLY }}</span>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault> with no repeats </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="verb === FeedVerb.FAIL">
      <span *ngIf="scan.extra?.reason" class="fail-reason">, reason: {{ scan.extra.reason }}</span>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="scan.labels?.length">
    <span>with labels:</span>
    <span>{{ scan.labels | join: ', ' }}</span>
  </ng-container>
</ng-template>

<ng-template #tmplIssue let-actor="actor" let-issue="object" let-target="target" let-verb="verb">
  <ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="verb === FeedVerb.CLOSE || verb === FeedVerb.REOPEN">
      <ng-container *ngTemplateOutlet="tmplActor; context: { $implicit: actor }"></ng-container>
      <span class="verb">{{ verb | feedVerbPastTense: issue.type }}</span>
      <span class="type">{{ issue.type | feedObjectType: verb }}</span>
      <a
        class="link link-internal"
        attr.aria-label="Navigate to issue {{ issue.name }}"
        [routerLink]="['/scans', target.id, 'issues', issue.id]"
        >{{ issue.name }}</a
      >
      <span>from scan</span>
      <a
        class="link link-internal"
        attr.aria-label="Navigate to scan {{ target.name }}"
        [routerLink]="['/scans', target.id]"
        >{{ target.name }}</a
      >
    </ng-container>
    <ng-container *ngSwitchCase="verb === FeedVerb.UPDATE">
      <ng-container *ngTemplateOutlet="tmplActor; context: { $implicit: actor }"></ng-container>
      <span class="verb">changed</span>
      <span class="type"
        >project issue
        <a
          *ngIf="target.id"
          class="link link-internal"
          attr.aria-label="Navigate to issue {{ issue.name }}"
          [routerLink]="['/projects', target.id, 'issues', issue.id]">
          {{ issue.name }}</a
        >
        <ng-container *ngIf="issue.extra?.statusFrom"
          >status from {{ issue.extra.statusFrom | titlecase }} to {{ issue.extra.statusTo | titlecase }}</ng-container
        >
        <ng-container *ngIf="issue.extra?.severityFrom"
          >severity from {{ issue.extra.severityFrom }} to {{ issue.extra.severityTo }}</ng-container
        >
      </span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <span class="type">New issue</span>
      <a
        class="link link-internal"
        attr.aria-label="Navigate to issue {{ issue.name }}"
        [routerLink]="['/scans', target.id, 'issues', issue.id]"
        >{{ issue.name }}</a
      >
      <span class="verb">{{ verb | feedVerbPastTense: issue.type }}</span>
      <span>during scan</span>
      <a
        class="link link-internal"
        attr.aria-label="Navigate to scan {{ target.name }}"
        [routerLink]="['/scans', target.id]"
        >{{ target.name }}</a
      >
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #tmplUser let-actor="actor" let-target="target" let-user="object" let-verb="verb">
  <ng-container *ngTemplateOutlet="tmplActor; context: { $implicit: actor }"></ng-container>
  <span class="verb">{{ verb | feedVerbPastTense: user.type }}</span>
  <span class="type">{{ user.type | feedObjectType: verb }}</span>
  <ng-container [ngSwitch]="verb">
    <ng-container *ngSwitchCase="FeedVerb.REMOVE">
      <span class="name-removed">{{ user.name }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="FeedVerb.ASSIGN">
      <a
        class="link link-internal"
        attr.aria-label="Navigate to user {{ user.name }}"
        [routerLink]="['/organization/members', user.id]"
        >{{ user.name }}</a
      >
      <span>to issue</span>
      <a
        *ngIf="target.extra?.scanId"
        class="link link-internal"
        attr.aria-label="Navigate to issue {{ target.name }}"
        [routerLink]="['/scans', target.extra.scanId, 'issues', target.id]"
        >{{ target.name }}</a
      >
      <a
        *ngIf="target.extra?.projectId"
        class="link link-internal"
        attr.aria-label="Navigate to issue {{ target.name }}"
        [routerLink]="['/projects', target.extra.projectId, 'issues', target.id]"
        >{{ target.name }}</a
      >
    </ng-container>
    <ng-container *ngSwitchCase="FeedVerb.UNASSIGN">
      <a
        class="link link-internal"
        attr.aria-label="Navigate to user {{ user.name }}"
        [routerLink]="['/organization/members', user.id]"
        >{{ user.name }}</a
      >
      <span>from issue</span>
      <a
        *ngIf="target.extra?.scanId"
        class="link link-internal"
        attr.aria-label="Navigate to issue {{ target.name }}"
        [routerLink]="['/scans', target.extra.scanId, 'issues', target.id]"
        >{{ target.name }}</a
      >
      <a
        *ngIf="target.extra?.projectId"
        class="link link-internal"
        attr.aria-label="Navigate to issue {{ target.name }}"
        [routerLink]="['/projects', target.extra.projectId, 'issues', target.id]"
        >{{ target.name }}</a
      >
    </ng-container>
    <ng-container *ngSwitchDefault>
      <a
        class="link link-internal"
        attr.aria-label="Navigate to user {{ user.name }}"
        [routerLink]="['/organization/members', user.id]"
        >{{ user.name }}</a
      >
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #tmplFile let-actor="actor" let-item="object" let-verb="verb">
  <ng-container *ngTemplateOutlet="tmplActor; context: { $implicit: actor }"></ng-container>
  <span class="verb">{{ verb | feedVerbPastTense: item.type }}</span>
  <span class="type">{{ item.type | feedObjectType: verb }}</span>
  <ng-container [ngSwitch]="verb">
    <ng-container *ngSwitchCase="FeedVerb.REMOVE">
      <span class="name-removed">{{ item.name }}</span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <a class="link link-internal" aria-label="Storage" [routerLink]="['/storage']">{{ item.name }}</a>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #tmplSubscription let-actor="actor" let-item="object" let-verb="verb">
  <ng-container *ngTemplateOutlet="tmplActor; context: { $implicit: actor }"></ng-container>
  <span class="verb">{{ verb | feedVerbPastTense: item.type }}</span>
  <span class="type">{{ item.type | feedObjectType: verb }}</span>
  <span class="name">{{ item.name }}</span>
</ng-template>

<ng-template #tmplComment let-actor="actor" let-comment="object" let-target="target" let-verb="verb">
  <ng-container *ngTemplateOutlet="tmplActor; context: { $implicit: actor }"></ng-container>
  <span class="verb">{{ verb | feedVerbPastTense: comment.type : true }}</span>
  <span>on</span>
  <span class="type">{{ target.type | feedObjectType }}</span>
  <ng-container [ngSwitch]="target.type">
    <ng-container *ngSwitchCase="FeedActivityObjectType.ISSUE">
      <a
        class="link link-internal"
        attr.aria-label="Navigate to scan issue {{ target.name }}"
        [routerLink]="['/scans', target.extra?.scanId, 'issues', target.id]"
        >{{ target.name }}</a
      >
      <ng-container *ngIf="target.extra?.scanName">
        <span>from scan</span>
        <a
          class="link link-internal"
          attr.aria-label="Navigate to scan {{ target.extra.scanName }}"
          [routerLink]="['/scans', target.extra.scanId]"
          >{{ target.extra.scanName }}</a
        >
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="FeedActivityObjectType.SCAN">
      <a
        class="link link-internal"
        attr.aria-label="Navigate to scan {{ target.name }}"
        [routerLink]="['/scans', target.id]"
        >{{ target.name }}</a
      >
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #tmplRepeater let-object="object" let-target="target" let-verb="verb">
  <span>The repeater</span>
  <span class="repeater">{{ object.name }}</span>

  <ng-container [ngSwitch]="verb">
    <ng-container *ngSwitchCase="FeedVerb.FAIL">
      <span>did not respond for</span>
      <span class="repeater-interval">{{ object.extra?.timeout | duration }}</span>
      <span>or more.</span>
    </ng-container>
    <span *ngSwitchCase="FeedVerb.STOP">has been disconnected.</span>
    <span *ngSwitchCase="FeedVerb.DEACTIVATE">has been deactivated.</span>
  </ng-container>

  <ng-container *ngIf="target?.id && target?.name">
    <span>Scan</span>
    <a
      class="link link-internal"
      attr.aria-label="Navigate to scan {{ target.name }}"
      [routerLink]="['/scans', target.id]"
      >{{ target.name }}</a
    >
    <span>has been stopped.</span>
  </ng-container>
</ng-template>

<ng-template #tmplBoardOrIssueSource let-actor="actor" let-item="object" let-target="target" let-verb="verb">
  <ng-container *ngTemplateOutlet="tmplActor; context: { $implicit: actor }"></ng-container>
  <span class="verb">{{ verb | feedVerbPastTense: item.type }}</span>
  <span class="type">{{ item.type | feedObjectType: verb }}</span>
  <ng-container [ngSwitch]="verb">
    <ng-container *ngSwitchCase="FeedVerb.REMOVE">
      <span class="name-removed">{{ item.name }} from project</span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <span class="name">{{ item.name }} in project</span>
    </ng-container>
  </ng-container>
  <a
    class="link link-internal"
    attr.aria-label="Navigate to project {{ target.name }}"
    [routerLink]="['/projects', target.id]"
    >{{ target.name }}</a
  >
</ng-template>

<ng-template #tmplIPRestriction let-actor="actor" let-item="object" let-verb="verb">
  <ng-container *ngTemplateOutlet="tmplActor; context: { $implicit: actor }"></ng-container>
  <span class="verb">{{ verb | feedVerbPastTense: item.type }}</span>
  <span class="type">
    <ng-container [ngSwitch]="verb">
      <ng-container *ngSwitchCase="FeedVerb.ENABLE">the</ng-container>
      <ng-container *ngSwitchCase="FeedVerb.DISABLE">the</ng-container>
      <ng-container *ngSwitchCase="FeedVerb.CREATE">a new</ng-container
      ><ng-container *ngSwitchDefault>an</ng-container></ng-container
    >
    IP restriction
    <ng-container *ngIf="verb !== FeedVerb.ENABLE && verb !== FeedVerb.DISABLE">range</ng-container></span
  >
  <ng-container [ngSwitch]="verb">
    <ng-container *ngSwitchCase="FeedVerb.REMOVE">
      <span class="name-removed">{{ item.name }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="FeedVerb.UPDATE">
      <span class="name">from {{ item.extra.from }} to {{ item.name }}</span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <span class="name">{{ item.name }}</span>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #tmplProject let-actor="actor" let-item="object" let-verb="verb">
  <span class="actor">{{ actor.name }}</span>

  <ng-container [ngSwitch]="verb">
    <ng-container *ngSwitchCase="FeedVerb.CREATE">
      <span class="verb">{{ verb | feedVerbPastTense: item.type }}</span> <span>a new project</span>
      <a
        class="link link-internal"
        attr.aria-label="Navigate to project {{ item.name }}"
        [routerLink]="['/projects', item.id]"
        >{{ item.name }}</a
      >
    </ng-container>
    <ng-container *ngSwitchCase="FeedVerb.UPDATE">
      <span class="verb">{{ verb | feedVerbPastTense: item.type }}</span> <span>a project name from</span>
      <span class="name-removed">{{ item.extra?.oldName }}</span> <span>to</span>
      <span class="name">{{ item.name }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="FeedVerb.REMOVE">
      <span>removed a project</span> <span class="name-removed">{{ item.name }}</span>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #tmplUnknown let-actor="actor" let-item="object" let-verb="verb">
  <ng-container *ngTemplateOutlet="tmplActor; context: { $implicit: actor }"></ng-container>
  <span class="verb">{{ verb | feedVerbPastTense: item.type }}</span>
  <span class="type">{{ item.type | feedObjectType: verb }}</span>
  <ng-container [ngSwitch]="verb">
    <ng-container *ngSwitchCase="FeedVerb.REMOVE">
      <span class="name-removed">{{ item.name }}</span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <span class="name">{{ item.name }}</span>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #tmplActor let-actor>
  <ng-container *ngIf="actor.type === FeedActivityActor.API_KEY">API key </ng-container>
  <span class="actor">{{ actor.name }}</span>
  <ng-container *ngIf="actor.extra?.type === 'project' && actor.extra?.name">
    (project <span class="actor-type">{{ actor.extra?.name }}</span
    >)
  </ng-container>
</ng-template>
