import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, map, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { type AuthProviderEnforcement } from '@neuralegion/api';
import { OrganizationsAuthProvidersService } from '../services';
import {
  disableForceSso,
  disableForceSsoFail,
  disableForceSsoSuccess,
  loadOrgAuthProvider,
  loadOrgAuthProviderFail,
  loadOrgAuthProviderSuccess
} from './org-auth-provider.actions';

@Injectable()
export class OrgAuthProviderEffects {
  public readonly loadAuthProvider$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrgAuthProvider),
      exhaustMap((action: ReturnType<typeof loadOrgAuthProvider>) =>
        this.organizationsAuthProvidersService.loadEnforcement(action.payload.orgId).pipe(
          map((res: AuthProviderEnforcement) => loadOrgAuthProviderSuccess({ authProvider: res })),
          catchError((err: HttpErrorResponse) => of(loadOrgAuthProviderFail(err.error)))
        )
      )
    )
  );

  public readonly disableForceSso$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(disableForceSso),
      exhaustMap((action: ReturnType<typeof disableForceSso>) =>
        this.organizationsAuthProvidersService.disableForceSso(action.payload.orgId).pipe(
          map(() => disableForceSsoSuccess()),
          catchError((err: HttpErrorResponse) => of(disableForceSsoFail(err.error)))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly organizationsAuthProvidersService: OrganizationsAuthProvidersService
  ) {}
}
