import { createAction } from '@ngrx/store';
import type { AuthProviderEnforcement } from '@neuralegion/api';

export const loadOrgAuthProvider = createAction(
  '[OrgAuthProvider] LOAD_ORG_AUTH_PROVIDER',
  (payload: { orgId: string }) => ({ payload })
);

export const loadOrgAuthProviderFail = createAction(
  '[OrgAuthProvider] LOAD_ORG_AUTH_PROVIDER_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadOrgAuthProvider.type
  })
);

export const loadOrgAuthProviderSuccess = createAction(
  '[OrgAuthProvider] LOAD_ORG_AUTH_PROVIDER_SUCCESS',
  (payload: { authProvider: AuthProviderEnforcement }) => ({
    payload,
    parentType: loadOrgAuthProvider.type
  })
);

export const disableForceSso = createAction(
  '[OrgAuthProvider] DISABLE_FORCE_SSO',
  (payload: { orgId: string }) => ({ payload })
);

export const disableForceSsoFail = createAction(
  '[OrgAuthProvider] DISABLE_FORCE_SSO_FAIL',
  (payload: string) => ({
    payload,
    parentType: disableForceSso.type
  })
);

export const disableForceSsoSuccess = createAction(
  '[OrgAuthProvider] DISABLE_FORCE_SSO_SUCCESS',
  () => ({
    parentType: disableForceSso.type
  })
);
