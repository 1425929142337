import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  shareReplay,
  takeUntil
} from 'rxjs';
import {
  ExternalFiltrationSelectFilter,
  ExternalFiltrationSettings,
  Filter,
  FilterConfig,
  SelectItem
} from '@neuralegion/api';
import { extractTouchedChanges } from '../../../form';
import { FilterControl } from '../../../models';
import { FindFilterPipe, FindPipe } from '../../../pipes';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './external-filtration-select.component.html',
  styleUrls: ['./external-filtration-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: ExternalFiltrationSelectComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: ExternalFiltrationSelectComponent, multi: true }
  ]
})
export class ExternalFiltrationSelectComponent implements FilterControl, OnInit, OnDestroy {
  @Input()
  public filterConfig: FilterConfig;

  @Input()
  public filters: Filter[];

  get settings(): ExternalFiltrationSettings {
    return this.filterConfig?.settings as ExternalFiltrationSettings;
  }

  get filter(): ExternalFiltrationSelectFilter | null {
    return this.findFilterPipe.transform<ExternalFiltrationSelectFilter>(
      this.filterConfig.name,
      this.filters
    );
  }

  get maxItemsLength(): number {
    return this.settings.maxItemsLength;
  }

  get preselectedItemsLength(): number {
    return this.filter?.selectedItemsLength ?? 0;
  }

  get totalSelectedItemsLength(): number {
    return (this.formControl.value?.length ?? 0) + this.preselectedItemsLength;
  }

  public readonly formControl = new FormControl<string[]>(null, [Validators.required]);

  public readonly isOptionDisabled = (item: string, selectedItems: string[] = []): boolean => {
    if (!this.maxItemsLength) {
      return false;
    }

    if (selectedItems.length + this.preselectedItemsLength < this.maxItemsLength) {
      return false;
    }

    return !selectedItems.includes(item);
  };

  public readonly itemsSortComparator = (a: string, b: string) => a.localeCompare(b);

  public readonly formatPipe = {
    transform: (itemId: string): string => {
      return (
        this.findItemByIdPipe.transform(itemId, this.itemsSubject.value, 'value')?.label || itemId
      );
    }
  };

  public items$: Observable<string[]>;

  private onChange: (value: string[]) => void;

  private onTouched: () => void;

  private readonly itemsSubject = new BehaviorSubject<SelectItem[]>([]);

  private readonly queryChangeSubject = new Subject<string>();

  private readonly gc = new Subject<void>();

  private readonly findFilterPipe = new FindFilterPipe();

  private readonly findItemByIdPipe = new FindPipe<SelectItem>();

  public ngOnInit(): void {
    extractTouchedChanges(this.formControl)
      .pipe(takeUntil(this.gc))
      .subscribe(() => this.onTouched?.());

    this.initItems();
    this.initValidation();
    this.initQueryChangeListener();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  public validate(): ValidationErrors | null {
    return this.formControl.valid ? null : { externalFiltrationSelect: true };
  }

  public writeValue(value: string[]): void {
    this.formControl.setValue(value);
  }

  public onOpenStateChanged(isOpened: boolean): void {
    if (!isOpened && !!this.onChange) {
      this.onChange(this.formControl.value);
      this.onTouched?.();
    }
    this.settings.onOpenedStateChanged(isOpened, this.filters);
  }

  public onQueryChange(query: string): void {
    this.queryChangeSubject.next(query);
  }

  private initValidation(): void {
    let maxLengthValidator: ValidatorFn;
    (this.filter?.valueChanges || of([])).pipe(takeUntil(this.gc)).subscribe(() => {
      if (maxLengthValidator) {
        this.formControl.removeValidators(maxLengthValidator);
      }

      maxLengthValidator = Validators.maxLength(this.maxItemsLength - this.preselectedItemsLength);
      this.formControl.addValidators(maxLengthValidator);
    });
  }

  private initQueryChangeListener(): void {
    this.queryChangeSubject
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.gc))
      .subscribe((query: string) => this.settings.onQueryChanged(query));
  }

  private initItems(): void {
    const itemsFromConfig$ = (this.filter?.availableItems$ || this.settings.items$).pipe(
      takeUntil(this.gc),
      shareReplay(1)
    );

    itemsFromConfig$.pipe(takeUntil(this.gc)).subscribe(this.itemsSubject);

    this.items$ = itemsFromConfig$.pipe(
      map((items: SelectItem[]) => items.map((item) => item.value))
    );
  }
}
