import { FeedActivityObjectType } from './feed-activity-object-type';
import { FeedVerb } from './feed-verb';

export const allowedVerbs: Record<FeedActivityObjectType, FeedVerb[]> = {
  [FeedActivityObjectType.BOARD]: [FeedVerb.CREATE, FeedVerb.REMOVE],
  [FeedActivityObjectType.COMMENT]: [FeedVerb.CREATE],
  [FeedActivityObjectType.FILE]: [FeedVerb.REMOVE, FeedVerb.UPLOAD],
  [FeedActivityObjectType.INTEGRATION]: [FeedVerb.ENABLE, FeedVerb.DISABLE],
  [FeedActivityObjectType.ISSUE]: [FeedVerb.CLOSE, FeedVerb.OPEN, FeedVerb.REOPEN, FeedVerb.UPDATE],
  [FeedActivityObjectType.ISSUE_SOURCE]: [FeedVerb.CREATE, FeedVerb.REMOVE],
  [FeedActivityObjectType.ORGANIZATION]: [FeedVerb.CREATE, FeedVerb.REMOVE],
  [FeedActivityObjectType.PAYMENT_METHOD]: [FeedVerb.CREATE, FeedVerb.REMOVE],
  [FeedActivityObjectType.REPEATER]: [FeedVerb.DEACTIVATE, FeedVerb.FAIL, FeedVerb.STOP],
  [FeedActivityObjectType.SCAN]: [
    FeedVerb.DISRUPT,
    FeedVerb.FAIL,
    FeedVerb.FINISH,
    FeedVerb.REMOVE,
    FeedVerb.SCHEDULE,
    FeedVerb.START,
    FeedVerb.STOP
  ],
  [FeedActivityObjectType.SUBSCRIPTION]: [
    FeedVerb.SUBSCRIBE,
    FeedVerb.UNSUBSCRIBE,
    FeedVerb.UPDATE
  ],
  [FeedActivityObjectType.USER]: [
    FeedVerb.ASSIGN,
    FeedVerb.CREATE,
    FeedVerb.REMOVE,
    FeedVerb.UNASSIGN
  ],
  [FeedActivityObjectType.IP_RESTRICTION]: [
    FeedVerb.DISABLE,
    FeedVerb.ENABLE,
    FeedVerb.CREATE,
    FeedVerb.UPDATE,
    FeedVerb.REMOVE
  ],
  [FeedActivityObjectType.PROJECT]: [FeedVerb.CREATE, FeedVerb.UPDATE, FeedVerb.REMOVE]
};
